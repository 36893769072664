@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";

$primary-parsley: #0D3D14;
$primary-lincoln-g: #14571E;
$primary-forest-g: #1E752B;
$primary-drk-olive-g: #526E2D;
$primary-moss-g: #809C55;
$primary-oliveine: #97B864;
$comp-vegas-gold: #D2B961;
$comp-golden-sand: #F5D871;
$comp-drover: #FCF3A6;
$mono-raisin-black: #1D2229;
$mono-charcoal: #454F5C;
$mono-slate: #6D7C8F;
$mono-platinum: #E7E7E7;
$mono-wild-sand: #F6F6F6;
$mono-white: white;
$valid-success: #008000;
$valid-success-dim: #C3EDC3;
$valid-alert: #FFA500;
$valid-alert-dim: #FCEED5;
$valid-error: #FF0000;
$valid-error-dim: #FFD7D7;
$valid-info: #34AFF7;
$accent-red: #FF5252;
$accent-blue: #34AFF7;
$accent-green: #2EDB4B;
$accent-yellow: #FFD43A;
$accent-base: #A0ABC0;


$green-grid-background: url("/assets/svg/gs-background.svg");
$light-brand: url("/assets/images/logos/regular/grid_sure.svg");
$dark-brand: url("/assets/images/logos/whitetext/grid_sure.svg");
$light-ui-brand: url("/assets/images/logos/regular/utility_innovation_group.svg");
$dark-ui-brand: url("/assets/images/logos/whitetext/utility_innovation_group.svg");
$arrow-forward: url("/assets/svg/arrowforward.svg");
$eye-open: url("/assets/icons/General/Eye.svg");
$eye-closed: url("/assets/icons/General/EyeOff.svg");
$logout-icon: url("/assets/icons/Interface/DoUndo.svg");
$options-icon: url("/assets/icons/General/Options.svg");
$search-icon: url("/assets/icons/General/Search.svg");
$disconnected-icon: url("/assets/icons/Communication/PhoneOff.svg");
$alarm-icon: url("/assets/icons/General/Notification.svg");
$alarm-icon-white: url("/assets/icons/General/NotificationWhite.svg");
$no-alarm-icon: url("/assets/icons/General/Notification Off.svg");
$volume-icon: url("/assets/icons/Media/Volume Up.svg");
$pause-icon: url("/assets/icons/Media/Player -  Pause.svg");
$hamburger-icon: url("/assets/icons/Interface/burger-menu.svg");
$hamburger-vertical-icon: url("/assets/icons/Interface/Menu Burger Vertical.svg");
$hamburger-vertical-icon-white: url("/assets/icons/Interface/Menu Burger Vertical White.svg");
$hamburger-vertical-icon-black: url("/assets/icons/Interface/Menu Burger Vertical Black.svg");
$email-icon: url("/assets/icons/Communication/Email.svg");
$attention-icon: url("/assets/icons/Interface/Attention Circle.svg");
$check-circle-icon: url("/assets/icons/Interface/Check Circle 1.svg");
$close-circle-icon: url("/assets/icons/Interface/Close Circle 1.svg");
$garbage-icon: url("/assets/icons/General/Trash Simple.svg");
$close-icon: url("/assets/icons/Interface/Close.svg");
$plus-icon: url("/assets/icons/Math/Sign-Plus.svg");
$edit-icon: url("/assets/icons/General/Edit.svg");
$truck-icon: url("/assets/icons/eCommerce/Delivery.svg");
$locked-icon: url("/assets/icons/General/Lock.svg");
$carrot-right-icon: url("/assets/icons/Arrow/carrot-right.svg");
$arrow-up-icon: url("/assets/icons/Arrow/Arrow Up 2.svg");
$arrow-down-icon: url("/assets/icons/Arrow/Arrow Down 2.svg");
$profile-circle-icon: url("/assets/icons/Communication/Profile Circle.svg");
$sidebar-left-icon: url("/assets/icons/Interface/layout-sidebar.svg");
$sidebar-right-icon: url("/assets/icons/Interface/layout-sidebar-reverse.svg");

$w01d: url("/assets/images/weather/01d@2x.png");
$w01n: url("/assets/images/weather/01n@2x.png");
$w02d: url("/assets/images/weather/02d@2x.png");
$w02n: url("/assets/images/weather/02n@2x.png");
$w03d: url("/assets/images/weather/03d@2x.png");
$w03n: url("/assets/images/weather/03n@2x.png");
$w04d: url("/assets/images/weather/04d@2x.png");
$w04n: url("/assets/images/weather/04n@2x.png");
$w09d: url("/assets/images/weather/09d@2x.png");
$w09n: url("/assets/images/weather/09n@2x.png");
$w10d: url("/assets/images/weather/10d@2x.png");
$w10n: url("/assets/images/weather/10n@2x.png");
$w11d: url("/assets/images/weather/11d@2x.png");
$w11n: url("/assets/images/weather/11n@2x.png");
$w13d: url("/assets/images/weather/13d@2x.png");
$w13n: url("/assets/images/weather/13n@2x.png");
$w50d: url("/assets/images/weather/50d@2x.png");
$w50n: url("/assets/images/weather/50n@2x.png");

$font-ESAllianz: "ES Allianz", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;

// mixin that enables css variables in light mode
@mixin lighten() {
  --gs-bg-page:  #{$primary-parsley};
  --gs-background: #{$mono-white};
  --gs-white: #{$mono-white};
  --gs-extra-dark: #{$primary-parsley};
  --gs-dark: #{$primary-lincoln-g};
  --gs-dark-fade: #{$primary-moss-g};
  --gs-light: #{$primary-oliveine};
  --gs-site-info-bg: #{$mono-wild-sand};
  --gs-site-info-title-text: #{$mono-raisin-black};
  --gs-site-info-text: #{$mono-charcoal};
  --gs-grid-header-background: #{$mono-wild-sand};
  --gs-grid-header-text: #{$mono-raisin-black};
  --gs-grid-header-border-b: #{$mono-wild-sand};
  --gs-row-hover: #{$mono-wild-sand};
  --gs-row-odd: #{$mono-white};
  --gs-cell-text-odd: #{$mono-charcoal};
  --gs-row-even: #{$mono-white};
  --gs-cell-text-even: #{$mono-charcoal};
  --gs-cell-th-border: #{$mono-slate};
  --gs-row-bottom-border-color: #{$mono-platinum};
  --gs-list-border-color: #{$mono-platinum};
  --gs-text-grey-color: #{$mono-charcoal};
  --gs-danger-fade: #{$valid-error-dim};
  --gs-danger: #{$valid-error};
  --gs-warn-fade: #{$valid-alert-dim};
  --gs-warn: #{$valid-alert};
  --gs-success-fade: #{$valid-success-dim};
  --gs-success: #{$valid-success};
  --gs-info: #{$valid-info};
  --gs-default: #{$mono-slate};
  --gs-text: black;
  --gs-img: #{$dark-brand};
  --gs-img-login: #{$light-brand};
  --ui-img-login: #{$dark-ui-brand};
  --ui-img: #{$light-ui-brand};
  --arrow-forward: #{$arrow-forward};
  --font-ESAllianz: #{$font-ESAllianz};
  --ui-animated-background: #{$green-grid-background};
}

// mixin that enables css variables in dark mode
@mixin darken() {
  --gs-bg-page:  #{$primary-parsley};
  --gs-background: #{$primary-parsley};
  --gs-white: #{$primary-parsley};
  --gs-extra-dark: #{$mono-white};
  --gs-dark: #{$primary-oliveine};
  --gs-dark-fade: #{$primary-moss-g};
  --gs-light: #{$primary-lincoln-g};
  --gs-site-info-bg: #{$mono-wild-sand};
  --gs-site-info-title-text: #{$mono-raisin-black};
  --gs-site-info-text: #{$mono-charcoal};
  --gs-grid-header-background: #{$mono-wild-sand};
  --gs-grid-header-text: #{$mono-raisin-black};
  --gs-grid-header-border-b: #{$mono-wild-sand};
  --gs-row-hover: #{$mono-wild-sand};
  --gs-row-odd: #{$mono-white};
  --gs-cell-text-odd: #{$mono-charcoal};
  --gs-row-even: #{$mono-white};
  --gs-cell-text-even: #{$mono-charcoal};
  --gs-cell-th-border: #{$mono-slate};
  --gs-row-bottom-border-color: #{$mono-platinum};
  --gs-list-border-color: #{$mono-platinum};
  --gs-text-grey-color: #{$mono-charcoal};
  --gs-danger-fade: #{$valid-error-dim};
  --gs-danger: #{$valid-error};
  --gs-warn-fade: #{$valid-alert-dim};
  --gs-warn: #{$valid-alert};
  --gs-success-fade: #{$valid-success-dim};
  --gs-success: #{$valid-success};
  --gs-info: #{$valid-info};
  --gs-default: #{$mono-slate};
  --gs-text: white;
  --gs-img: #{$dark-brand};
  --gs-img-login: #{$light-brand};
  --ui-img-login: #{$dark-ui-brand};
  --ui-img: #{$dark-ui-brand};
  --arrow-forward: #{$arrow-forward};
  --font-ESAllianz: #{$font-ESAllianz};
  --ui-animated-background: #{$green-grid-background};
}

@font-face {
  font-display: swap;
  font-family: 'ES Allianz';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/es-allianz-regular-subset.woff2) format("woff2");
  unicode-range:U + 20-7e
}

@font-face {
  font-display: swap;
  font-family: 'ES Allianz';
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/es-allianz-bold-subset.woff2) format("woff2");
  unicode-range:U + 20-7e
}

@font-face {
  font-display: swap;
  font-family: 'ES Allianz';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/es-allianz-light-subset.woff2) format("woff2");
  unicode-range:U + 20-7e
}

body {
  --tw-text-opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  color: rgb(0 0 0/var(--tw-text-opacity));
  font-family: var(--font-ESAllianz);
  font-size: 1rem;
  font-weight: 400;
  line-height:1.26
}

body.dark {
  @include darken();
}

body.light {
  @include lighten();
}

.gs-grid-background {
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-size:cover;
  background-image: var(--ui-animated-background);
}

.gs-site-info-bg {
  background-color: var(--gs-site-info-bg);
}

.gs-site-info-title-text {
  color: var(--gs-site-info-title-text);
  background-color: var(--gs-site-info-bg);
  text-align: left;
}

.gs-site-info-text {
  color: var(--gs-site-info-text);
  text-align: left;
}

.gs-logo {
  content: var(--gs-img);
}

.ue-logo {
  content: var(--ui-img);
}

.gs-logo-login {
  content: var(--gs-img-login);
}

.ui-logo-login {
  content: var(--ui-img-login);
}

.arrow-fwd {
  content: var(--arrow-forward);
}

.arrow-up-icon { 
  content: $arrow-up-icon;
}

.arrow-down-icon {
  content: $arrow-down-icon;
}

.eye-open {
  content: $eye-open;
  cursor: pointer;
}

.eye-closed {
  content: $eye-closed;
  cursor: pointer;
}

.profile-icon {
  content: $profile-circle-icon;
}

.logout-icon {
  content: $logout-icon;
}

.options-icon {
  content: $options-icon;
}

.search-icon {
  content: $search-icon;
}

.disconnected-icon {
  content: $disconnected-icon;
}

.alarm-icon {
  content: $alarm-icon;
}

.alarm-icon-white {
  content: $alarm-icon-white;
}

.no-alarm-icon {
  content: $no-alarm-icon;
}

.email-icon {
  content: $email-icon;
}

.volume-up-icon {
  content: $volume-icon;
}

.attention-icon {
  content: $attention-icon;
}

.hamburger-icon {
  content: $hamburger-icon;
}

.hamburger-vertical-icon {
  content: $hamburger-vertical-icon;
}

.hamburger-vertical-icon-white {
  content: $hamburger-vertical-icon-white;
}

.hamburger-vertical-icon-black {
  content: $hamburger-vertical-icon-black;
}

.circle-check-icon {
  content: $check-circle-icon;
}

.circle-cross-icon {
  content: $close-circle-icon;
}

.garbage-icon {
  content: $garbage-icon;
}

.close-icon {
  content: $close-icon;
}

.plus-icon {
  content: $plus-icon;
}

.edit-icon {
  content: $edit-icon;
}

.truck-icon {
  content: $truck-icon;
}

.locked-icon {
  content: $locked-icon;
}

.carrot-right-icon {
  content: $carrot-right-icon;
}

.pause-icon {
  content: $pause-icon;
}

.layout-left-icon {
  content: $sidebar-left-icon;
}

.layout-right-icon {
  content: $sidebar-right-icon;
}

.w01d {
  content: $w01d;
}

.w01n {
  content: $w01n;
}

.w02d {
  content: $w02d;
}

.w02n {
  content: $w02n;
}

.w03d {
  content: $w03d;
}

.w03n {
  content: $w03n;
}

.w04d {
  content: $w04d;
}

.w04n {
  content: $w04n;
}

.w09d {
  content: $w09d;
}

.w09n {
  content: $w09n;
}

.w10d {
  content: $w10d;
}

.w10n {
  content: $w10n;
}

.w11d {
  content: $w11d;
}

.w11n {
  content: $w11n;
}

.w13d {
  content: $w13d;
}

.w13n {
  content: $w13n;
}

.w50d {
  content: $w50d;
}

.w50n {
  content: $w50n;
}

.filter-white {
  filter: invert(100%) sepia(49%) saturate(76%) hue-rotate(308deg) brightness(115%) contrast(100%);
}

.gs-background {
  background-color: var(--gs-background) !important;
}

.gs-bg-page {
  background-color: var(--gs-bg-page) !important;
}

.gs-text {
  color: var(--gs-text) !important;
}

.bg-gs-success {
  background-color: var(--gs-success);
}

.bg-gs-warning {
  background-color: var(--gs-warn);
}

.bg-gs-info {
  background-color: var(--gs-info);
}

.bg-gs-default {
  background-color: var(--gs-default);
}

.bg-gs-extra-dark {
  background-color: var(--gs-extra-dark) !important;
}

.bg-gs-dark {
  background-color: var(--gs-dark) !important;
}

.bg-gs-dark-fade {
  background-color: var(--gs-dark-fade) !important;
}

.bg-gs-light {
  background-color: var(--gs-light) !important;
}

.bg-gs-white {
  background-color: var(--gs-white) !important;
}

.border-gs-dark {
  border-color: var(--gs-dark) !important;
}

.border-gs-dark-fade {
  border-color: var(--gs-dark-fade) !important;
}

.border-gs-light {
  border-color: var(--gs-light) !important;
}

.border-gs-list {
  border-color: var(--gs-list-border-color) !important;
}

.bg-gs-danger {
  background-color: var(--gs-danger) !important;
}

.bg-gs-danger-fade {
  background-color: var(--gs-danger-fade) !important;
}

.bg-gs-warn-fade {
  background-color: var(--gs-warn-fade) !important;
}

.bg-gs-success-fade {
  background-color: var(--gs-success-fade) !important;
}

.bg-gs-unknown {
  background-color: $mono-wild-sand !important;
}

.text-gs-dark {
  color: var(--gs-dark) !important;
}

.text-validate {
  height: 1.5rem;
  display: flex;
  width: 278px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.text-gs-error {
  color: var(--gs-danger);
}

.text-gs-warn {
  color: var(--gs-warn);
}

.text-gs-success {
  color: var(--gs-success);
}

.leaflet-div-icon {
  width:0;
  height:0;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.leaflet-bar a {
  color: white;
  background-color: var(--gs-dark);
  border-width: 1px;
  border-color: white;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: solid 2px;
}

.login-info {
  align-self: stretch;
  color: var(--gs-text-grey-color);
  font-family: 'ES Allianz', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.checkbox-wrapper-6 .tgl {
  display: none;
}
.checkbox-wrapper-6 .tgl,
.checkbox-wrapper-6 .tgl:after,
.checkbox-wrapper-6 .tgl:before,
.checkbox-wrapper-6 .tgl *,
.checkbox-wrapper-6 .tgl *:after,
.checkbox-wrapper-6 .tgl *:before,
.checkbox-wrapper-6 .tgl + .tgl-btn {
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl::-moz-selection,
.checkbox-wrapper-6 .tgl:after::-moz-selection,
.checkbox-wrapper-6 .tgl:before::-moz-selection,
.checkbox-wrapper-6 .tgl *::-moz-selection,
.checkbox-wrapper-6 .tgl *:after::-moz-selection,
.checkbox-wrapper-6 .tgl *:before::-moz-selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-6 .tgl::selection,
.checkbox-wrapper-6 .tgl:after::selection,
.checkbox-wrapper-6 .tgl:before::selection,
.checkbox-wrapper-6 .tgl *::selection,
.checkbox-wrapper-6 .tgl *:after::selection,
.checkbox-wrapper-6 .tgl *:before::selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::selection {
  background: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:after,
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:after {
  left: 0;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  display: none;
}
.checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn {
  background: #f0f0f0;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}
.checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
  background: var(--gs-dark-fade);
}


input[type="text"].ui-grid-filter-input {
  outline: 0;
  color: var(--gs-text-grey-color);
  background-color: $mono-white;
  border: 2px;
  border-style: solid;
  border-color: $mono-platinum;
  height: 24px;
  padding: 24px 24px;
  align-items: center;
  align-self: stretch;
  font-family: var(--font-ESAllianz);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

input[type="text"],
input[type="password"],
input[type="email"] {
  outline: 0;
  color: var(--gs-text-grey-color);
  background-color: $mono-white;
  border: 2px;
  border-style: solid;
  border-color: $mono-platinum;
  height: 24px;
  padding: 24px 24px;
  align-items: center;
  align-self: stretch;
  font-family: var(--font-ESAllianz);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

input[type="text"]:active,
input[type="password"]:active,
input[type="email"]:active {
  border: 2px;
  border-style: solid;
  color: $mono-raisin-black;
  border-color: $primary-parsley;
}

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled {
  border: 2px;
  border-style: solid;
  color: var(--gs-text-grey-color);
  border-color: $mono-slate;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  border: 4px;
  border-style: solid;
  color: $mono-raisin-black;
  border-color: $mono-platinum;
}

input[type="text"].ng-invalid.ng-touched,
input[type="password"].ng-invalid.ng-touched,
input[type="email"].ng-invalid.ng-touched {
  border-color: var(--gs-danger) !important;
  color: $mono-raisin-black !important;
}

input[type="text"].ng-valid.ng-touched,
input[type="password"].ng-valid.ng-touched,
input[type="email"].ng-valid.ng-touched {
  background-color: $mono-white !important;
  color: $mono-raisin-black !important;
}

input.form-check-input {
  color: var(--gs-text-grey-color);
  background-color: $mono-white;
  border: 2px solid;
  border-color: $mono-platinum;
  border-radius: 0;
  height: 24px;
  padding: 12px 12px;
  align-items: center;
}

input.form-control {
  color: var(--gs-text-grey-color);
  background-color: $mono-white;
  border: 2px solid;
  border-color: $mono-platinum;
  border-radius: 0;
  height: 48px;
  padding: 12px 24px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-family: var(--font-ESAllianz);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

select.form-select {
  color: var(--gs-text-grey-color);
  background-color: $mono-white;
  border: 2px solid;
  border-color: $mono-platinum;
  border-radius: 0;
  height: 48px;
  padding: 12px 28px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-family: var(--font-ESAllianz);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

select.form-select[multiple] {
  height: 90%;
  padding: 12px 28px;
}

select.form-select.short {
  height: 100%;
  padding: 0px 28px;
}

select.ui-grid-filter-select {
  color: var(--gs-text-grey-color);
  background-color: $mono-white;
  border: 2px solid;
  border-color: $mono-platinum;
  border-radius: 0;
  height: 48px;
  padding: 12px 24px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-family: var(--font-ESAllianz);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.status-badge {
  padding: 13px 12px 14px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

button.gs-btn {
  display: inline-flex;
  padding: 13px 32px 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background-color: $primary-oliveine;
  color: $primary-parsley;
}

button.gs-btn:hover {
  background-color: $primary-parsley;
  color: $primary-oliveine;
}

button.gs-btn:active {
  background-color: $primary-parsley;
  color: $primary-moss-g;
}

button.gs-btn:disabled {
  background-color: $mono-platinum;
  color: $mono-slate;
}

button.gs-btn:focus {
  background-color: $primary-oliveine;
  color: $primary-parsley;
  border-width: 4px;
  border-color: $primary-moss-g;
}

button.gs-btn.btn-option {
  border-radius: 0;
  border: 2px solid;
  border-color: $primary-lincoln-g !important;
  background-color: $primary-lincoln-g !important;
  color: $mono-white !important;
}

button.gs-btn.btn-option:hover {
  background-color: $primary-forest-g !important;
  color: $mono-white !important;
}

button.gs-btn.btn-option:active {
  border: 2px solid;
  border-color: $mono-platinum !important;
  background-color: $mono-white !important;
  color: $mono-raisin-black !important;
}

button.gs-btn.btn-option.active {
  border: 2px solid;
  border-color: $mono-platinum !important;
  background-color: $mono-white !important;
  color: $mono-raisin-black !important;
}

button.gs-btn.btn-option.isActive {
  border: 2px solid;
  border-color: $mono-platinum !important;
  background-color: $mono-white;
  color: $mono-raisin-black;
}

button.gs-btn.secondary {
  display: inline-flex;
  padding: 13px 32px 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background-color: $mono-white;
  color: $primary-parsley;
  border-width: 2px;
  border-color: $mono-platinum;
}

button.gs-btn.secondary:hover {
  background-color: $mono-white;
  color: $primary-parsley;
  border-color: $primary-parsley;
}

button.gs-btn.secondary:active {
  background-color: $mono-wild-sand;
  color: $primary-parsley;
  border-color: $primary-parsley;
}

button.gs-btn.secondary:disabled {
  background-color: $mono-white;
  color: $mono-platinum;
  
}

button.gs-btn.secondary:focus {
  background-color: $mono-white;
  color: $primary-parsley;
  border-width: 4px;
  border-color: $mono-platinum;
}

button.gs-btn.tertiary {
  display: inline-flex;
  padding: 4px 4px 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: $mono-white;
  color: $primary-parsley;
}

button.gs-btn.tertiary:hover {
  background-color: $mono-white;
  color: $primary-parsley;
  border-bottom: 2px;
  border-style: solid;
  border-color: $primary-parsley;
}

button.gs-btn.tertiary:active {
  background-color: $mono-wild-sand;
  color: $primary-parsley;
  border-bottom: 2px;
  border-color: $primary-oliveine;
}

button.gs-btn.tertiary:disabled {
  background-color: $mono-white;
  color: $mono-platinum;
  
}

button.gs-btn.tertiary:focus {
  background-color: $mono-white;
  color: $primary-parsley;
  border-width: 3px;
  border-color: $mono-platinum;
}

//- Override tailwind.css collapse class
.collapse {
  visibility: visible !important;
}

.fixTableHead {
  overflow-y: auto;
  height: 110px;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
}

.listTable {
  width: 100%;
}

.listTable th {
  color: var(--gs-grid-header-text);
  height: 53px;
  padding: 7px;
  background-color: var(--gs-grid-header-background);
}

.listTable tr {
  background-color: $mono-wild-sand;
  border: rbga(255, 255, 255, 1) 1px;
}

.listTable tr:nth-child(odd) {
  background-color: var(--gs-row-odd);
}

.listTable tr:nth-child(even) {
  background-color: var(--gs-row-even);
}

.navbar.with-border {
  border-bottom: 1px solid $primary-lincoln-g;
}

.nav-tabs .nav-link {
  color: var(--gs-dark) !important;
}

.nav-tabs .nav-link:hover {
  color: var(--gs-text) !important;
  background-color: var(--gs-light) !important;
}

.nav-tabs .nav-link.active {
  color: var(--gs-text) !important;
  background-color: var(--gs-light) !important;
}

.dropdown-menu {
  border-radius: 0;
}

// /* ============ desktop view ============ */
// @media all and (min-width: 992px) {
//   .navbar .nav-item .dropdown-menu {
//     display: none;
//     margin-top: 0;
//   }

//   .navbar .nav-item:hover .dropdown-menu {
//     display: block;
//   }
// }

// /* ============ desktop view .end// ============ */

.page-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  background: $mono-white;
}

.section-title-container {
  display: flex;
  flex-direction: row;
  padding: 8px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.page-title-text {
  color: $mono-white;
  text-align: center;
  /* Body/Body 18 - Bold */
  font-family: var(--font-ESAllianz);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
}

.section-title-text {
  color: $mono-raisin-black;
  /* Body/Body 16 - Bold */
  font-family: var(--font-ESAllianz);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}

.bg-not-running-disconnected {
  background-color: var(--gs-text-grey-color);
}

.bg-running {
  background-color: $primary-forest-g;
}

.bg-running-in-alarm {
  background-color: var(--gs-warn);
}

.bg-running-in-crit-alarm {
  background-color: var(--gs-danger);
}

.gs-spinner-color {
  color: $primary-forest-g;
}

.nav-item .nav-link {
  color: $mono-white;
}

.screen-head {
  height: calc(100vh - 83px);
}

.screen-head-title {
  height: calc(100vh - 83px - 58px);
}

.full-title {
  height: calc(100% - 26px);
}

.half-title {
  height: calc(50% - 17px);
}

.report-area {
  height: calc(100% - 167px);
}

.accordion-button:not(.collapsed) {
  background-color: $mono-white !important;
}

.ui-grid-cell-table-th {
  width: 100%;
  min-height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: var(--gs-row-bottom-border-color);
}

.ui-grid-cell-table-th>label{
  position:absolute;
  top:1px;
  left:1px;
  background-color:transparent;
  font-size: small;
}

.top-left-label {
  position:absolute;
  top:1px;
  left:1px;
  background-color:transparent;
  font-size: small;
}

.ui-grid-cell-table-th.left {
  padding-left: 2rem;
  justify-content: start !important;
}


.ui-grid-cell-table-td {
  width: 100%;
  min-height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--gs-row-bottom-border-color);
  background-color: $mono-white;
}

.ui-grid-cell-table-td.left {
  padding-left: 2rem;
  justify-content: start !important;
}

.table-th {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: var(--gs-grid-header-background);
}

.table-td {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--gs-row-bottom-border-color);
  background-color: $mono-white;
}

.section-border {
  border: 1px solid var(--gs-row-bottom-border-color);
}

.ui-grid-header-canvas {
  background-color: var(--gs-grid-header-background);
}

.ui-grid-header {
  color: var(--gs-grid-header-text);
  border-bottom: 2px solid var(--gs-grid-header-border-b);
}

.ui-grid-header-cell {
  color: var(--gs-grid-header-text);
  background-color: var(--gs-grid-header-background);
  border-right: 1px solid var(--gs-grid-header-border-b);
}

.ui-grid-cell,
.ui-grid-row:nth-child(odd) .ui-grid-cell {
  color: var(--gs-cell-text-odd);
  background-color: var(--gs-row-odd);
}

.ui-grid-cell,
.ui-grid-row:nth-child(even) .ui-grid-cell {
  color: var(--gs-cell-text-even);
  background-color: var(--gs-row-even);
}

.ui-grid-row:hover>[ui-grid-row]>.ui-grid-cell:hover .ui-grid-cell,
.ui-grid-row:nth-child(odd):hover .ui-grid-cell,
.ui-grid-row:nth-child(even):hover .ui-grid-cell {
  background-color: var(--gs-row-hover);
}

.ui-grid-pinned-container.ui-grid-pinned-container-left .ui-grid-cell:last-child {
  border-width: 0px 1px 0px 0px;
}

.ui-grid-row.ui-grid-row-selected>[ui-grid-row]>.ui-grid-cell {
  background-color: var(--gs-row-hover);
}

.grid-height {
  height: '378px';
}

.map-legends {
  z-index: 2147483647;
}

.map-legend {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 6px 8px;
  color: #555;
  font-size: 14px;

  .legend-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  li {
    margin-bottom: 4px;
  }

  .color-row {
    line-height: 15px;
  }

  .legend-square {
    width: 30px;
    height: 22px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    top: 1px;
  }

  .storm-square {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    top: 1px;
    opacity: 1;
  }

  .run-alarm-legend {
    color: white;
    background: orange;
    opacity: 1;
  }

  .running-legend {
    color: white;
    background: green;
    opacity: 1;
  }

  .ready-legend {
    color: white;
    background: blue;
    opacity: 1;
  }

  .alarm-legend {
    color: white;
    background: red;
    opacity: 1;
  }

  .disconnect-legend {
    color: white;
    background: gray;
    opacity: 1;
  }

  .legend-icon {
    background: none;
    margin-right: 5px;
  }

  .pathColor {
    background: #2ee1ea;
    opacity: 0.2;
  }

  .firstWind {
    background: #00ffa1;
    opacity: 0.36;
  }

  .secondWind {
    background: #d0ff00;
    opacity: 0.36;
  }

  .thirdWind {
    background: #ff0000;
    opacity: 0.36;
  }

}

.weather-legends {
  z-index: 2147483647;
}

.weather-legend {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 6px 8px;
  color: #555;
  font-size: 14px;

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  .legend-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  li {
    margin-bottom: 4px;
  }

  .color-row {
    line-height: 15px;
  }

  .legend-square {
    width: 30px;
    height: 22px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    top: 1px;
  }

  .mm0 {
    background: rgba(225, 200, 100, 0);
  }

  .mm0-1 {
    background: rgba(200, 150, 150, 0);
  }

  .mm0-2 {
    background: rgba(150, 150, 170, 0);
  }

  .mm0-5 {
    background: rgba(120, 120, 190, 0);
  }

  .mm1 {
    background: rgba(110, 110, 205, 0.3);
  }

  .mm10 {
    background: rgba(80, 80, 225, 0.7);
  }

  .mm140 {
    background: rgba(20, 20, 255, 0.9);
  }

  .cn65 {
    background: rgba(130, 22, 146, 1);
  }

  .cn30 {
    background: rgba(130, 87, 219, 1);
  }

  .cn20 {
    background: rgba(32, 140, 236, 1);
  }

  .cn10 {
    background: rgba(32, 196, 232, 1);
  }

  .c0 {
    background: rgba(35, 221, 221, 1);
  }

  .c10 {
    background: rgba(194, 255, 40, 1);
  }

  .c20 {
    background: rgba(255, 240, 40, 1);
  }

  .c25 {
    background: rgba(255, 194, 40, 1);
  }

  .c30 {
    background: rgba(252, 128, 20, 1);
  }
}
